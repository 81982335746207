import React from "react"
import { useRouter } from "next/router"
import { scroller } from "react-scroll"

import { useGlobalContext } from "../../context/layout-context"

function MyPosition({ noName, bar }) {
  const router = useRouter()
  const { globalState, updateGlobalState } = useGlobalContext()
  const { headerHeight } = globalState || {}

  // Get Current Location Coordinates
  const getUserPosition = () => {
    // if user is already on /find page, then we don't need do a redirect
    if (router.pathname !== "/find" || router.asPath !== "/find") {
      router.push("/find")
    }

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        updateGlobalState({
          setCenter: [position.coords.latitude, position.coords.longitude],
          setLat: position.coords.latitude,
          setLng: position.coords.latitude,
        })
      })
    }
    if (
      router.pathname === "/find" ||
      router.asPath === "/find" ||
      router.pathname.includes("/find") ||
      router.asPath.includes("/find")
    ) {
      scroller.scrollTo("locations-container", {
        duration: 300,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: -headerHeight,
      })
    }
  }

  return (
    <React.StrictMode>
      {!bar ? (
        <button
          type="button"
          className={`btn btn-icon p-2 xl:px-2 md:pt-4 md:pb-3 xl:pl-8 normal-case font-noto bg-white ${
            !noName
              ? "xl:bg-opacity-40 xl:border-2px xl:border-yellow-700 xl:hover:border-neon-red-400"
              : ""
          }`}
          onClick={getUserPosition}
          onKeyPress={getUserPosition}
        >
          <svg
            viewBox="0 0 25 24"
            className="icon text-transparent fill-current w-6 h-6 relative xl:absolute xl:left-2 xl:inset-y-0 m-auto"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12.575 16.5a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM13.7 1.125a1.125 1.125 0 1 0-2.25 0v1.944a9.005 9.005 0 0 0-7.806 7.806H1.7a1.125 1.125 0 1 0 0 2.25h1.944a9.004 9.004 0 0 0 7.806 7.806v1.944a1.125 1.125 0 1 0 2.25 0v-1.944a9.005 9.005 0 0 0 7.806-7.806h1.944a1.125 1.125 0 1 0 0-2.25h-1.944A9.004 9.004 0 0 0 13.7 3.069V1.125ZM12.575 18.75a6.75 6.75 0 1 1 0-13.5 6.75 6.75 0 0 1 0 13.5Z" />
          </svg>
          {!noName && <span className="pl-1 hidden xl:block">My position</span>}
        </button>
      ) : (
        <button
          type="button"
          className="btn-icon w-45px h-45px normal-case font-noto bg-white relative flex items-center justify-center"
          onClick={getUserPosition}
          onKeyPress={getUserPosition}
        >
          <svg
            viewBox="0 0 25 24"
            className="icon text-transparent fill-current w-6 h-6 relative m-auto"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12.575 16.5a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM13.7 1.125a1.125 1.125 0 1 0-2.25 0v1.944a9.005 9.005 0 0 0-7.806 7.806H1.7a1.125 1.125 0 1 0 0 2.25h1.944a9.004 9.004 0 0 0 7.806 7.806v1.944a1.125 1.125 0 1 0 2.25 0v-1.944a9.005 9.005 0 0 0 7.806-7.806h1.944a1.125 1.125 0 1 0 0-2.25h-1.944A9.004 9.004 0 0 0 13.7 3.069V1.125ZM12.575 18.75a6.75 6.75 0 1 1 0-13.5 6.75 6.75 0 0 1 0 13.5Z" />
          </svg>
        </button>
      )}
    </React.StrictMode>
  )
}

export default MyPosition
